import request from '@/utils/request'
import qs from 'qs';

// 获取专题和二级专题
export function getSpec() {
  return request({
    url: '/product/spec',
    method: 'post'
  })
}

// 根据专题查找教师
export function getTeacher(data) {
  return request({
    url: 'teacher/getTeacherBySpec',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询某个老师的基本信息
export function getTeacherDetail(data) {
  return request({
    url: 'teacher/detail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询某个老师的课程
export function getGrade(data) {
  return request({
    url: 'teacher/getGrade',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询某个老师的套餐
export function getCombo(data) {
  return request({
    url: 'teacher/getCombo',
    method: 'post',
    data: qs.stringify(data)
  })
}
