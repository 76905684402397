<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <div class="container">
      <!-- 专题 -->
      <div class='industry-test-wrap container'>
        <el-card class='box-card'>
          <ul>
            <li>
              <div
                class="list-title industry-title"
                :class="'special-nav-parent-item' + (currentSpecial.id == special.id ? ' industryActiveStyle' : '')"
                v-for='special in specialList'
                @click="doChangeSpecial(special)"
                :key='special.id'>
                  {{special.title}}
              </div>
            </li>
            <li>
              <div class="list-title test-title" :class="currentSpecialChild.id == 0 ? 'testActiveStyle' : ''" @click="doChangeSpecialChild(null)">全部</div>
              <div
                :class="currentSpecialChild.id == child.id ? 'testActiveStyle' : ''"
                class="list-title test-title"
                @click="doChangeSpecialChild(child)"
                v-for='child in specialChildList'
                :key='child.id'>
                  {{child.title}}
              </div>
            </li>
          </ul>
        </el-card>
      </div>
      <!-- 列表 -->
      <div class="teacher-content">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(teacher, index) in teacherList" :key="index">
            <div class="teacher-card">
              <el-card>
                <div class="top">
                  <div class="image"><img :src="teacher.thumb" /></div>
                  <div class="content">
                    <span class="title">{{ teacher.name }}</span>
                    <br />
                    <div class="bottom">
                      <el-button :type="$config.buttonType" size="mini" @click="goTeacherDetail(teacher)">
                        查看详情
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="bottom">{{ stripHTML(teacher.content) }}</div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from "@/components/page/NavBar";
import ToolBox from "@/components/toolbox";
import WebFooter from "@/components/page/WebFooter";
import { getSpec, getTeacher } from "@/api/teacher";
import { stripHTML } from "@/utils/index";

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
  },
  data() {
    return {
      // 专题列表
      specialList: [],
      // 当前选中的专题
      currentSpecial: {},
      // 二级专题列表
      specialChildList: [],
      // 当前选中的二级专题
      currentSpecialChild: {id: 0},
      // 教师列表
      teacherList: [],
    };
  },
  created() {
    getSpec().then(res => {
      if (res.code == 1) {
        this.specialList = res.data
        this.currentSpecial = this.specialList[0]
        this.specialChildList = this.currentSpecial.children
        this.currentSpecialChild = {id: 0}
        // 默认查询第一个专题的全部老师
        this.getList(1, res.data[0].id)
      }
    })
  },
  methods: {
    // 获取教师
    getList(type, specialId) {
      getTeacher({type: type, spec_id: specialId}).then(res => {
        if (res.code == 1) {
          this.teacherList = res.data
        }
      })
    },
    // 切换专题
    doChangeSpecial(special) {
      this.currentSpecial = special
      this.specialChildList = special.children
      // 默认切换全部
      this.currentSpecialChild = {id: 0}
      this.getList(1, special.id)
    },
    // 切换二级专题
    doChangeSpecialChild(specialChild) {
      if (specialChild != null) {
        this.currentSpecialChild = specialChild
        this.getList(2, specialChild.id)
      } else {
        this.currentSpecialChild = {id: 0}
        this.getList(1, this.currentSpecial.id)
      }
    },
    // 去除html标签
    stripHTML,
    // 教师详情
    goTeacherDetail(teacher) {
      this.$router.push('/teacherdetail/' + teacher.id)
    },

  },
};
</script>

<style scoped lang="scss">
.container {
  margin: 20px auto;
}

// 专题 begin
.industry-test-wrap{
  margin-top:20px;
  ul{
    padding:10px 20px;
    li{
      display: flex;
      flex-direction: row;
      align-items: center;
      height:70px;
      border-bottom:1px solid $info-color;
      .list-title{
        font-size:16px;
        margin-right:30px;
      }
      .industry-title{
        font-size:18px;
        margin-right:70px;
      }
      .industry-title.industryActiveStyle{
        color:$theme-color;
        font-weight: bold;
      }
      .test-title.testActiveStyle{
        color:$theme-color;
      }
      
    }
    li:last-child{
      border-bottom:none;
    }
  }
}
// 专题 end

// 列表 begin
.teacher-content {
  padding: 0px 10px 10px 10px;

  .teacher-card {
    height: 310px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    transition-duration: 0.5s;
    transition-property: box-shadow;

    &:hover {
      box-shadow: 0px 0px 3px 3px #ccc;
    }
    .top {
      height: 200px;
      padding: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .image {
        width: 150px;
        height: 200px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .content {
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        .title {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .bottom {
      padding: 0px 10px 10px 10px;
      height: 60px;
      margin-bottom: 10px;
      overflow: hidden;
      line-height: 22px;
    }
  }
}
// 列表 end
</style>
